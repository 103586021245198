import { render, staticRenderFns } from "./App.vue?vue&type=template&id=95866cae&shadow"
import script from "./App.vue?vue&type=script&lang=js&shadow"
export * from "./App.vue?vue&type=script&lang=js&shadow"
function injectStyles (context) {
  
  var style0 = require("./App.vue?vue&type=style&index=0&lang=scss&shadow")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  ,true
)

export default component.exports