import Vue from "vue";

export const state = Vue.observable({
  clientId: 0,
  mapId: 0,
  mapName: "",
  edit: false,
  measureFrom: null,
  measureTo: null,
  layerType: null,
  legendType: null,
  convertMeasure: false,
  scaleLegend: null,
  cdUser: null,
  lenColors: process.env.VUE_APP_DEFAULT_RANGE_QTD_LEGEND,
  legendToApply: [],
  legend: [],
  disabledRanges: [],
  editMode: false,
  minValue: 0,
  maxValue: 0,
  profiles: [
    {
      cd_id: 0,
      cd_cliente: 0,
      nome: "-",
      cd_tipomapa: 0,
      cd_usuario: "0",
      legenda: []
    }
  ],
  modelProfile: 0,
  distributionMethod: 2,
  customDistribution: null,
  buttonLoading: false,
  disableSave: false,
  percentageSearch: false,
  auxiliaryFlag: false,
  showSettings: false,
  minimizeLegend: false,
  errorMessage: null,
  dateSelected: {
    startDateTime: 0,
    endDateTime: 0
  },
  showColorPicker: false,
  multiMeasurements: null,
  rangePercentages: {},
  disableRangeSelection: false
});

export const getters = {
  getClientId: () => state.clientId,
  getMapId: () => state.mapId,
  getMapName: () => state.mapName,
  getEdit: () => state.edit,
  getMeasureFrom: () => state.measureFrom,
  getMeasureTo: () => state.measureTo,
  getLayerType: () => state.layerType,
  getLegendType: () => state.legendType,
  getConvertMeasure: () => state.convertMeasure,
  getScaleLegend: () => state.scaleLegend,
  getLengthColors: () => state.lenColors,
  getLegendToApply: () => state.legendToApply,
  getDisabledRanges: () => state.disabledRanges,
  getLegend: () => state.legend,
  getEditMode: () => state.editMode,
  getCdUser: () => state.cdUser,
  getMinValue: () => state.minValue,
  getMaxValue: () => state.maxValue,
  getProfiles: () => state.profiles,
  getModelProfile: () => state.modelProfile,
  getDistributionMethod: () => state.distributionMethod,
  getCustomDistribution: () => state.customDistribution,
  getButtonLoading: () => state.buttonLoading,
  getDisableSave: () => state.disableSave,
  getPercentageSearch: () => state.percentageSearch,
  getAuxiliaryFlag: () => state.auxiliaryFlag,
  getShowSettings: () => state.showSettings,
  getMinimizeLegend: () => state.minimizeLegend,
  getErrorMessage: () => state.errorMessage,
  getDateSelected: () => state.dateSelected,
  getShowColorPicker: () => state.showColorPicker,
  getMultiMeasurements: () => state.multiMeasurements,
  getRangePercentages: () => state.rangePercentages,
  getDisableRangeSelection: () => state.disableRangeSelection
};

export const mutations = {
  setClientId: pClientId => {
    state.clientId = pClientId;
  },
  setMapId: pMapId => {
    state.mapId = pMapId;
  },
  setMapName: pMapName => {
    state.mapName = pMapName;
  },
  setEdit: pEdit => {
    state.edit = pEdit;
  },
  setMeasureFrom: pMeasureFrom => {
    state.measureFrom = pMeasureFrom;
  },
  setMeasureTo: pMeasureTo => {
    state.measureTo = pMeasureTo;
  },
  setLayerType: pLayerType => {
    state.layerType = pLayerType;
  },
  setLegendType: pLegendType => {
    state.legendType = pLegendType;
  },
  setConvertMeasure: pConvertMeasure => {
    state.convertMeasure = pConvertMeasure;
  },
  setScaleLegend: pScaleLegend => {
    state.scaleLegend = pScaleLegend;
  },
  setLengthColors: pLengthColors => {
    state.lenColors = parseInt(pLengthColors);
  },
  setLegendToApply: pLegendToApply => {
    state.legendToApply = pLegendToApply;
  },
  setDisabledRanges: pDisabledRanges => {
    state.disabledRanges = pDisabledRanges;
  },
  setLegend: pLegend => {
    state.legend = pLegend;
  },
  setEditMode: pEditMode => {
    state.editMode = pEditMode;
  },
  setCdUser: pCdUser => {
    state.cdUser = pCdUser;
  },
  setMinValue: pMinValue => {
    state.minValue = pMinValue;
  },
  setMaxValue: pMaxValue => {
    state.maxValue = pMaxValue;
  },
  setProfiles: pProfiles => {
    state.profiles = pProfiles;
  },
  setModelProfile: pModelProfile => {
    state.modelProfile = pModelProfile;
  },
  setDistributionMethod: pDistributionMethod => {
    state.distributionMethod = pDistributionMethod;
  },
  setCustomDistribution: pCustomDistribution => {
    state.customDistribution = pCustomDistribution;
  },
  setButtonLoading: pButtonLoading => {
    state.buttonLoading = pButtonLoading;
  },
  setDisableSave: pDisableSave => {
    state.disableSave = pDisableSave;
  },
  setPercentageSearch: pPercentageSearch => {
    state.percentageSearch = pPercentageSearch;
  },
  setAuxiliaryFlag: pAuxiliaryFlag => {
    state.auxiliaryFlag = pAuxiliaryFlag;
  },
  setShowSettings: pShowSettings => {
    state.showSettings = pShowSettings;
  },
  setMinimizeLegend: pMinimizeLegend => {
    state.minimizeLegend = pMinimizeLegend;
  },
  setErrorMessage: pErrorMessage => {
    state.errorMessage = pErrorMessage;
  },
  setDateSelected: pDateSelected => {
    state.dateSelected = pDateSelected;
  },
  setShowColorPicker: pShowColorPicker => {
    state.showColorPicker = pShowColorPicker;
  },
  setMultiMeasurements: pMultiMeasurements => {
    state.multiMeasurements = pMultiMeasurements;
  },
  setRangePercentages: pRangePercentages => {
    state.rangePercentages = pRangePercentages;
  },
  setDisableRangeSelection: pDisableRangeSelection => {
    state.disableRangeSelection = pDisableRangeSelection;
  }
};

export const actions = {
  setMapIdToDefaultProfile: pMapId => {
    state.profiles[0].cd_tipomapa = pMapId;
  },
  setDefaultProfile: pProfile => {
    /* Seta  o perfil padrão. */
    state.profiles[0] = pProfile;
  },
  removeProfile: pIndex => {
    /* Remove um perfil da lista de legendas de acordo com o índice passado por parâmetro. */
    state.profiles.splice(pIndex, 1);
  }
};
