// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900);"]);
exports.push([module.id, "@import url(https://cdn.jsdelivr.net/npm/@mdi/font@latest/css/materialdesignicons.min.css);"]);
exports.push([module.id, "@import url(https://cdn.jsdelivr.net/npm/vuetify@2.x/dist/vuetify.min.css);"]);
// Module
exports.push([module.id, ".custom-scrollbar::-webkit-scrollbar-track{border-radius:10px;border:1px solid #ccc}.custom-scrollbar::-webkit-scrollbar{width:10px;background-color:#f5f5f5}.custom-scrollbar::-webkit-scrollbar-thumb{border-radius:10px;background:-webkit-gradient(linear,left top,right top,from(#fff),to(#e4e4e4));background:linear-gradient(90deg,#fff,#e4e4e4);border:1px solid #aaa}.custom-scrollbar::-webkit-scrollbar-thumb:hover{background:#fff}.custom-scrollbar::-webkit-scrollbar-thumb:active{background:-webkit-gradient(linear,left top,right top,from(#22add4),to(#1e98ba));background:linear-gradient(90deg,#22add4,#1e98ba)}.v-application--wrap{min-height:auto}.v-application .error{background-color:#ff5252!important;border-color:#ff5252!important}.v-application .primary{background-color:#6ead42!important;border-color:#6ead42!important}", ""]);
// Exports
module.exports = exports;
