import Vue from "vue";
/* Observable responsável por gerenciar as urls do wc. Dinamicas de acordo com o Grupo (props) */
export const state = Vue.observable({
  urlServiceGo: "",
  token: ""
});

export const getters = {
  getUrlServiceGo: () => state.urlServiceGo,
  getToken: () => state.token
};

export const mutations = {
  setUrlServiceGo: pUrlServiceGo => {
    state.urlServiceGo = pUrlServiceGo;
  },
  setToken: pToken => {
    state.token = pToken;
  }
};
