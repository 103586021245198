<template>
  <component
    class="custom-scrollbar wc-map-legend"
    style="overflow-y: auto; overflow-x: hidden;"
    :style="getShowColorPicker || getShow ? 'height: 210px;' : ''"
    v-bind="propsDown"
    :is="mainComponent"
    @dynamicEvent="dynamicEvent"
  ></component>
</template>

<script>
import i18n from "./i18n";
import vuetify from "./plugins/vuetify";
import {
  getters as gettersGlobal,
  mutations as mutationsGlobal
} from "./observables/Global";
import { mutations as mutationsUrl } from "./observables/Url";
import { getters as gettersErrorModal } from "./observables/ErrorModal";

export default {
  i18n,
  vuetify,
  name: "App",
  props: {
    clientId: {
      type: Number,
      default: () => 113
    },
    locale: {
      type: String,
      default: () => "pt-br"
    },
    cdUser: {
      type: String,
      default: () => "douglas.silva"
    },
    eventColor: {
      type: String,
      default: () => "orange"
    },
    group: {
      type: Number,
      default: () => 2
    },
    legend: {
      type: String,
      default: () => {
        return JSON.stringify([0.03, 1.74, 2.85, 3.49, 3.99]);
      }
    },
    mapTypeSelected: {
      type: String,
      default: () => {
        return JSON.stringify({
          id: 8,
          edit: true,
          measureFrom: "t/ha",
          measureTo: "const.units.t/ha",
          layerType: "LINE",
          legendType: "number",
          convertMeasure: true,
          scaleLegend: ["red", "yellow", "green", "blue"],
          name: "mass"
        });
      }
    },
    minMaxRange: {
      type: String,
      default: () => "[0,0]"
    },
    zIndex: {
      type: String,
      default: function() {
        return "1";
      }
    },
    token: {
      type: String,
      default: () => {
        return "eyJhbGciOiJIUzUxMiIsInppcCI6IkRFRiJ9.eNokjUELwjAMhf-K5Dylm3OIR9GzMA96DbUrgS4pazcF8b-b6vF7X_LeG-TJboIDWBmsQAWOF5qER8dZ02N_uV3PveYzkwZ1BcQpI1unNoXB7NQlK_HHPqIi41joJLMPmFZ3XEgnKgjIfkZfXMzrY2nNVE73XWuMevHEKh__x02isJQ-94q63BmzbZumrT9fAAAA__8.Q_knk5p1kFCErOmhhYBk08I_Ye4czZYC3f-XISYALVyr6YiVfXX9ZHaKxP4tflQGpT8ByyL4dXF2MKkAwC5fDw";
      }
    },
    urlServiceGo: {
      type: String,
      required: true
    },
    rangePercentages: {
      type: String,
      default: () => {
        return "";
      }
    },
    disableRangeSelection: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      loaded: false
    };
  },
  computed: {
    ...gettersGlobal,
    ...gettersErrorModal,
    mainComponent() {
      /* Retorna o componente Main.vue para ser renderizado após o carregamento de todas as props. */
      return this.loaded ? () => import("./components/Main") : null;
    },
    propsDown() {
      return Object.assign({}, this.$props);
    }
  },
  mounted() {
    this.injectStyle();
    this.setUrl();
    this.loaded = true;
  },
  activated() {
    /* Reativa o componente Main */
    this.loaded = true;
  },
  deactivated() {
    /* Desativa o componente Main */
    this.loaded = false;
  },
  methods: {
    dynamicEvent(pEvent) {
      /* Os Watchs estão localizados no componente Main.vue, para que eles não se dupliquem conforme o
      componente pai ativa e desativa o componente da legenda. */
      this.$emit(pEvent.name, pEvent.value);
    },
    injectStyle() {
      /* Aplica o link necessário para que os ícones sejam renderizados. */
      const links = Array.from(document.querySelectorAll("link")).map(
        n => n.href
      );
      const fontUrl =
        "https://cdn.jsdelivr.net/npm/@mdi/font@latest/css/materialdesignicons.min.css";
      if (links.indexOf(fontUrl) === -1) {
        const link = document.createElement("link");
        link.rel = "stylesheet";
        link.href = fontUrl;
        document.head.appendChild(link);
      }
    },
    setUrl() {
      mutationsUrl.setUrlServiceGo(this.urlServiceGo);
      mutationsUrl.setToken(this.token);
    }
  },
  watch: {
    rangePercentages() {
      if (this.rangePercentages) {
        mutationsGlobal.setRangePercentages(JSON.parse(this.rangePercentages));
      }
    },
    locale: {
      immediate: true,
      handler() {
        /* Fica observando mudanças na tradução referente a props locale. */
        this.$i18n.locale = this.locale;
      }
    }
  }
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900");
@import url("https://cdn.jsdelivr.net/npm/@mdi/font@latest/css/materialdesignicons.min.css");
@import url("https://cdn.jsdelivr.net/npm/vuetify@2.x/dist/vuetify.min.css");

// custom scrollbar for webkit
.custom-scrollbar {
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    border: 1px solid #ccc;
  }
  &::-webkit-scrollbar {
    width: 10px;
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: linear-gradient(to right, #fff, #e4e4e4);
    border: 1px solid #aaa;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #fff;
  }

  &::-webkit-scrollbar-thumb:active {
    background: linear-gradient(to right, #22add4, #1e98ba);
  }
}

.v-application--wrap {
  min-height: auto;
}

.v-application .error {
  background-color: #ff5252 !important;
  border-color: #ff5252 !important;
}

.v-application .primary {
  background-color: #6ead42 !important;
  border-color: #6ead42 !important;
}

.subtitle-2,
.caption {
  // line-height: 0.85rem !important;
}
</style>
