import Vue from "vue";

export const state = Vue.observable({
  show: false,
  message: ""
});

export const getters = {
  getShow: () => state.show,
  getMessage: () => state.message
};

export const mutations = {
  setShow: pShow => {
    state.show = pShow;
  },
  setMessage: pMessage => {
    state.message = pMessage;
  }
};
