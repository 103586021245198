import Vue from "vue";

import Vuetify, {
  VApp,
  VMain,
  VContainer,
  VRow,
  VCol,
  VToolbar,
  VBtn,
  VIcon,
  VMenu,
  VDivider,
  VSelect,
  VMessages,
  VScaleTransition,
  VList,
  VChip,
  VListItem,
  VListItemContent,
  VListItemTitle,
  VTextField,
  VRadioGroup,
  VRadio,
  VCard,
  VCardTitle,
  VCardText,
  VAutocomplete,
  VAlert,
  VItemGroup,
  VTab,
  VTabItem,
  VTabReverseTransition,
  VTabTransition,
  VTableOverflow,
  VTabs,
  VTabsItems,
  VTabsSlider,
  VProgressCircular,
  VSwitch,
  VColorPicker
} from "vuetify/lib";

import { Ripple } from "vuetify/lib/directives";

Vue.use(Vuetify, {
  components: {
    VApp,
    VMain,
    VContainer,
    VRow,
    VCol,
    VToolbar,
    VBtn,
    VIcon,
    VMenu,
    VDivider,
    VSelect,
    VMessages,
    VScaleTransition,
    VList,
    VChip,
    VListItem,
    VListItemContent,
    VListItemTitle,
    VTextField,
    VRadioGroup,
    VRadio,
    VCard,
    VCardTitle,
    VCardText,
    VAutocomplete,
    VAlert,
    VItemGroup,
    VTab,
    VTabItem,
    VTabReverseTransition,
    VTabTransition,
    VTableOverflow,
    VTabs,
    VTabsItems,
    VTabsSlider,
    VProgressCircular,
    VSwitch,
    VColorPicker
  },
  directives: {
    Ripple
  }
});

const opts = {
  theme: {
    themes: {
      light: {
        primary: "#4AA9B7"
      }
    }
  }
};

export default new Vuetify(opts);
